import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from 'src/hooks/redux';
import Button from 'src/shared/Button'
import moment from "moment"
import { toast } from 'react-hot-toast';

function SubscriptionSetting() {
  const { userData: user } = useAppSelector((state) => state.common);
  const { isProUser = false, subDurationinMonths, subEndDate, subStartDate } = user || {}
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant='h4' mb={3}>Current Plan</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={7} md={5.5}>
              <Box display="flex" justifyContent="space-between">
                <Typography>Tier:</Typography>
                <Typography>{isProUser ? `Pro Plan | ${subDurationinMonths} Month(s)` : 'Basic Plan'} </Typography>
              </Box>
            </Grid>
          </Grid>
          {isProUser ? <Grid container>
            <Grid item xs={12} sm={7} md={5.5}>
              <Box display="flex" justifyContent="space-between">
                <Typography>Active Until:</Typography>
                <Typography>{subEndDate ? moment(Number(subEndDate) * 1000).format("DD MMMM yyyy") : ''}</Typography>
              </Box>
            </Grid>
          </Grid> : <Grid item xs={12}>
            <Box mt={3} sx={{ width: { xs: '100%', sm: '300px' } }}>
              <Button size='medium' sx={{ width: "100%" }} onClick={() => window.open('/subscribe', '_self')}>Upgrade Subscription</Button>
            </Box>
          </Grid>}
        </Grid>
        {isProUser ? <><Grid item xs={12}>
          <Box mt={3} sx={{ width: { xs: '100%', sm: '300px' } }}>
            <Button size='medium' sx={{ width: "100%" }} onClick={() => toast.success('Subscription Paused')}>Pause Subscription</Button>
          </Box>
        </Grid>
          <Grid item xs={12}>
            <Box mt={2} sx={{ width: { xs: '100%', sm: '300px' } }}>
              <Button size='medium' sx={{ width: "100%" }} onClick={() => window.open('/subscribe', '_self')}>Renew Subscription</Button>
            </Box>
          </Grid></> : null}
      </Grid>
    </div>
  )
}

export default SubscriptionSetting