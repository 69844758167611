import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import * as action from "src/redux/actions/common";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { setLoader } from "src/redux/reducers/common";
import Button from 'src/shared/Button';
import { defaultThemeColor, telegramBotUsername } from 'src/constants';
import CustomModal from 'src/shared/Modal';
import { Typography } from '@mui/material';

function TelegramSetting() {
  const dispatch = useAppDispatch();
  const [chatToken, setChatToken] = useState<string>("");
  const [shortChatToken, setShortChatToken] = useState<string>("");
  const [tokenModal, setTokenModal] = useState<boolean>(false);

  const chatTokenLoader = useAppSelector((state) => state.common.chatTokenLoader);
  const isAppLoading = useAppSelector((state) => state.common.isAppLoading);
  const isLoggedIn = useAppSelector((state) => state.common.isLoggedIn);

  useEffect(() => {
    if (chatTokenLoader) {
      dispatch(setLoader({ action: true }));
    } else {
      dispatch(setLoader({ action: false }));
    }
  }, [chatTokenLoader]);

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchChatToken();
  }, [isLoggedIn]);

  const fetchChatToken = () => {
    dispatch(action.checkForChatToken()).then((res) => {
      const { payload = {} } = res as any;
      setShortChatToken(payload.lastDigits || "");
    });
  };

  const removeChatToken = () => {
    dispatch(action.removeChatToken()).then(() => {
      toast.success("Chat token removed");
      fetchChatToken();
    });
  };

  const generateChatToken = () => {
    dispatch(action.generateChatToken()).then((res) => {
      const { payload = {} } = res as any;
      toast.success("Chat token generated successfully");
      setTokenModal(true);
      fetchChatToken();
      setChatToken(payload.chatToken || "");
    });
  };

  const onCloseModal = () => {
    setTokenModal(false);
    setChatToken("");
  };

  const onClickCopyText = () => {
    copy(chatToken);
    toast.success("Text copied");
  };

  if (isAppLoading) {
    return null
  }

  return (
    <div>
      <Grid container>

        {shortChatToken ? (
          <Grid item xs={12}>
            <Box mt={3} sx={{ fontSize: '18px' }}>Token: <span style={{ border: `2px solid ${defaultThemeColor}`, padding: '5px 20px' }}>{shortChatToken ? `**********${shortChatToken}` : "none"}</span></Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant='h6' mb={3}>Generate Chat Token</Typography>
              <Button fill size="medium" onClick={generateChatToken}>
                Generate Token
              </Button>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mt={4}>
            {shortChatToken ? (
              <Button fill size="medium" theme="danger" onClick={removeChatToken}>
                Remove Chat Token
              </Button>
            ) : null}
          </Box>
        </Grid>
        {shortChatToken ? <Grid item xs={12}>
          <Box mt={3}>
            <Button fill size="medium" onClick={() => window.open(`https://telegram.me/${telegramBotUsername}`)}>
              Go to Pikatweet TG Bot
            </Button>
          </Box>
        </Grid> : null}
      </Grid>
      <CustomModal maxWidth="sm" title="Generated Chat Token" open={tokenModal} showClose disableOutsideClose onClose={onCloseModal}>
        <Typography sx={{ marginTop: 2, textAlign: "center" }}>Click on the box below to copy text</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px",
          }}
        >
          <Typography
            sx={{
              background: "#222222",
              padding: "8px 12px",
              borderRadius: "16px",
              borderStyle: "dotted",
              borderColor: "#23a523",
              cursor: "pointer",
              wordBreak: "break-all",
            }}
            onClick={onClickCopyText}
          >
            {chatToken}
          </Typography>
        </Box>
        <Typography sx={{ marginTop: 1, color: "#fe5353", textAlign: "center", fontSize: "1.1rem" }}>
          Note: Make sure to copy your chat token. You won't be able to see it again!
        </Typography>
      </CustomModal>
    </div>
  )
}

export default TelegramSetting