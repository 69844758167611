import { removeUsersFromListAPI } from "./../apis/list";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addUsersToListAPI, createListAPI, deleteListAPI, getAllListAPI, getListByIdAPI, updateListAPI } from "../apis/list";

export const getAllLists = createAsyncThunk("list/getAllLists", () => getAllListAPI());
export const getListById = createAsyncThunk("list/getListById", (userId: string) => getListByIdAPI(userId));
export const createList = createAsyncThunk("list/createList", (data: { name: string }) => createListAPI(data));
export const updateList = createAsyncThunk("list/updateList", ({ listId, data }: { listId: string; data: { name: string } }) => updateListAPI(listId, data));
export const deleteList = createAsyncThunk("list/deleteList", (userId: string) => deleteListAPI(userId));
export const addUsersToList = createAsyncThunk("list/addUsersToList", ({ listId, data }: { listId: string; data: string[] }) =>
  addUsersToListAPI(listId, data)
);
export const removeUsersFromList = createAsyncThunk("list/removeUsersFromList", ({ listId, data }: { listId: string; data: string[] }) =>
  removeUsersFromListAPI(listId, data)
);
