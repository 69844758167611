import "./app.scss";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import MyList from "./pages/MyLists";
import Subscribe from "./pages/Subscribe";
import { CircularProgress } from "@mui/material";
import { useAppSelector } from "./hooks/redux";
import Settings from "./pages/Settings";
import { defaultThemeColor } from "./constants";

function App() {
  const isAppLoading = useAppSelector((state) => state.common.isAppLoading);
  const loaderMessage = useAppSelector((state) => state.common.appLoaderMsg);

  return (
    <div className="App">
      <Header />
      {isAppLoading ? (
        <div className="main-app-loader">
          <div>
            <CircularProgress size={30} sx={{ color: defaultThemeColor }} />
            {!!loaderMessage ? <p>{loaderMessage}</p> : null}
          </div>
        </div>
      ) : null}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/lists" element={<MyList />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </div>
  );
}

export default App;
