import MuiButton from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import { defaultThemeColor, defaultThemeColorSec } from "src/constants";

interface IButton {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  theme?: "light" | "dark" | "danger";
  sx?: {},
  onClick: () => void;
  action?: "action" | "add",
  fill?: boolean
}

function Button(props: IButton) {
  const { size = "small", theme = "dark", onClick = () => { }, sx = {}, action = "action", fill = false } = props;
  return (
    <MuiButton
      variant="contained"
      onClick={onClick}
      sx={{
        ...sx,
        color: fill ? "#000000" : theme === "light" ? "#03A9F4" : "white",
        cursor: "pointer",
        position: "relative",
        zIndex: 2,
        background: fill ? defaultThemeColor : "none",
        border: '3px solid rgb(212, 251, 95)',
        borderRadius: 0,
        fontSize:
          size === "medium" ? "18px" : size === "large" ? "20px" : "14px",
        textTransform: "none",
        padding: "2px 16px",
        boxShadow: "none",
        ":hover": {
          // color: fill ? "white" : "inherit",
          background:
            theme === "light"
              ? "#ffffff"
              : theme === "danger"
                ? "#c03f3f"
                : fill ? defaultThemeColorSec : "#4f4f4f",
        },
      }}
    >
      {props.children}
      {action === "add" && <><AddIcon sx={{
        color: "#D4FB5F",
        position: 'absolute',
        bottom: '-18.419px',
        right: '8px',
        zIndex: 1,
        fontSize: '33px',
      }} />
        <span style={{
          position: 'absolute',
          bottom: '-4px',
          right: '9px',
          width: '30px',
          height: '4px',
          background: 'black',
          zIndex: '0'
        }} /></>}
    </MuiButton>
  );
}

export default Button;
