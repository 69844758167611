import { Box, Grid, Typography } from "@mui/material";
import { defaultThemeColor } from "src/constants";
import Button from "src/shared/Button";
import { IplanOptions } from ".";


interface IPlansCardContainer {
  planOptions: IplanOptions[];
  onClickStart: (optionId) => void
}

export default function PlansCardContainer(props: IPlansCardContainer) {
  const { planOptions = [], onClickStart } = props
  return (
    <Grid container spacing={8} className="subscribe-container">
      {planOptions.map((item) => {
        return (
          <Grid item xs={12} sm={12} md={6} key={item.id}>
            <Box
              component="div"
              maxWidth="400px"
              minHeight="360px"
              width="100%"
              margin="auto"
              textAlign="center"
              borderRadius={0}
              p={4}
              boxSizing="border-box"
              border={`3px solid ${defaultThemeColor}`}
              position="relative"
              sx={{
                background: item.isRecommended
                  // ? `linear-gradient(180deg, ${defaultThemeColor} 0%, #1ED2E3 100%);`
                  ? `rgba(212, 251, 95, 0.15);`
                  : "",
                // outline: "3px solid white",
                transition: "all 200ms ease-in",
                ":hover": {
                  transform: "scale(1.02)",
                  transition: "all 200ms ease-in",
                },
              }}
            // outline="3px solid white"
            >
              {item.isRecommended && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-4%',
                    right: '6%',
                    zIndex: '1',
                    backgroundColor: '#D4FB5F',
                    borderRadius: '0',
                    padding: '4px 24px'
                  }}
                >
                  <Typography sx={{ fontSize: '1rem', color: 'black' }}>RECOMMENDED</Typography>
                </Box>
              )}
              <Typography
                variant="h4"
                sx={{
                  paddingBottom: 2,
                  color: item.isRecommended ? "#ffffff" : "",
                  textTransform: "uppercase",
                  letterSpacing: "0.3rem",
                }}
              >
                {item.name}
              </Typography>
              <Box
                // bgcolor={!item.isRecommended ? "#F2F9FF" : "#ffffff"}
                bgcolor="#d4fb5f17"
                minHeight="100px"
                borderRadius={4}
                textAlign="start"

                p={2}
              >
                <Typography variant="h5" sx={{ paddingBottom: 1 }}>
                  Features
                </Typography>
                <ul style={{ listStyleType: "circle", marginTop: 0 }}>
                  {item.features.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </Box>
              <Typography
                sx={{
                  margin: "12px 0px",
                  color: item.isRecommended ? "#ffffff" : "",
                  fontSize: "1.3rem",
                }}
              >
                {item.price}
              </Typography>
              {item.isActive ? <Typography mt={3}>Current Plan</Typography> : <Button
                size="large"
                // theme={item.isRecommended ? "light" : "dark"}
                onClick={() => onClickStart(item.id)}
              >
                START NOW
              </Button>}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}