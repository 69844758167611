import { useState, useEffect, useMemo } from "react";
import { Avatar, Box, CircularProgress, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import Button from "src/shared/Button";
import CustomSelect from "src/shared/CustomSelect";
import CustomModal from "src/shared/Modal";
import SearchUser from "src/shared/SearchUser";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { addUsersToList, createList, deleteList, getAllLists, getListById, removeUsersFromList, updateList } from "src/redux/actions/list";
import { removeSingleList } from "src/redux/reducers/list";
import { toast } from "react-hot-toast";
import { setLoader } from "src/redux/reducers/common";
import { defaultThemeColor } from "src/constants";

export default function MyList() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [listName, setListName] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<any>({});

  const dispatch = useAppDispatch();
  const lists = useAppSelector((state) => state.list.lists);
  const listLoader = useAppSelector((state) => state.list.allListLoader) as boolean;
  const singleList = useAppSelector((state) => state.list.singleList) as any;
  const singleListLoader = useAppSelector((state) => state.list.singleListLoader) as boolean;
  const addRemoveUserLoader = useAppSelector((state) => state.list.addRemoveUserLoader) as boolean;
  const deleteLoader = useAppSelector((state) => state.list.deleteLoader) as boolean;
  const createUpdateLoader = useAppSelector((state) => state.list.createUpdateLoader) as boolean;
  const isLoggedIn = useAppSelector((state) => state.common.isLoggedIn);

  const { trackedUsers = [] } = singleList as any;

  // handle loaders
  useEffect(() => {
    if (listLoader || addRemoveUserLoader || deleteLoader || createUpdateLoader) {
      dispatch(setLoader({ action: true }));
    } else {
      dispatch(setLoader({ action: false }));
    }
  }, [listLoader, addRemoveUserLoader, deleteLoader, createUpdateLoader]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (!lists.length) {
      dispatch(getAllLists()).then(({ payload }: any) => {
        const { values = [] } = payload;
        if (Array.isArray(values) && values.length) {
          const selectedItem = values[0];
          setSelectedList({ ...selectedItem, label: selectedItem.name, value: selectedItem._id });
          dispatch(getListById(selectedItem._id));
        }
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (Object.keys(singleList).length && !Object.keys(selectedList).length) {
      setSelectedList({
        label: singleList.name,
        value: singleList._id,
      });
    }
  }, [singleList, isLoggedIn]);

  const onSelectMenuItem = (item) => {
    setSelectedList(item);
    dispatch(getListById(item.value));
  };

  const onSubmitCreateList = () => {
    if (!!listName) {
      if (isEdit) {
        dispatch(updateList({ listId: singleList._id, data: { name: listName } })).then(() => {
          setListName("");
          setCreateModal(false);
          setIsEdit(false);
          toast.success("List updated");
          dispatch(getAllLists());
        })
      } else {
        dispatch(createList({ name: listName })).then(async (res: any) => {
          console.log(res, "resss")
          if (typeof res === "object" && res.hasOwnProperty('error') && res.error && res.error.name === "AxiosError") {
            return
          }
          toast.success("List created");
          setListName("");
          setCreateModal(false);
          dispatch(getAllLists()).then(({ payload }: any) => {
            const { values = [] } = payload;
            if (Array.isArray(values) && values.length) {
              const selectedItem = values[0];
              setSelectedList({ ...selectedItem, label: selectedItem.name, value: selectedItem._id });
              dispatch(getListById(selectedItem._id));
            }
          });
        }).catch(err => {
          console.log(err, "errrr")
        })
      }
    }
  };

  const onDeleteList = (id: string) => {
    dispatch(deleteList(id)).then(() => {
      toast.success("List deleted successfully");
      dispatch(removeSingleList());
      dispatch(getAllLists()).then(({ payload }: any) => {
        const { values = [] } = payload;
        if (Array.isArray(values) && values.length) {
          const selectedItem = values[0];
          setSelectedList({ ...selectedItem, label: selectedItem.name, value: selectedItem._id });
          dispatch(getListById(selectedItem._id));
        }
      });
    });
  };

  const onEditList = () => {
    setCreateModal(true);
    setIsEdit(true);
    setListName(singleList.name || "");
  };

  const onAddUserToList = (twitterId: string) => {
    if (singleList._id) {
      const userIds = [twitterId];
      dispatch(addUsersToList({ listId: singleList._id, data: userIds })).then((res: any) => {
        if (typeof res === "object" && res.hasOwnProperty('error') && res.error && res.error.name === "AxiosError") {
          return
        }
        toast.success("User added to the list");
        dispatch(getListById(singleList._id));
      });
    }
  };

  const onRemoveUserFromList = (trackingUserId: string) => {
    if (singleList._id) {
      const userIds = [trackingUserId];
      dispatch(removeUsersFromList({ listId: singleList._id, data: userIds })).then(() => {
        toast.success("User removed from the list");
        dispatch(getListById(singleList._id));
      });
    }
  };

  const modifiedList = useMemo(() => {
    return lists.map((item: any) => ({ ...item, label: item.name, value: item._id }));
  }, [lists]);

  const isMobileView = useMediaQuery("(max-width:600px)");

  if (!isLoggedIn) {
    return (
      <div className="mylist-container">
        <Box className="login-description" mt={12}>
          Please login to use this feature
        </Box>
      </div>
    );
  }

  return (
    <div className="mylist-container">

      <Container maxWidth="lg">
        <Box mt={4}>
          <SearchUser onAddUser={onAddUserToList} />
        </Box>
        <Box
          mt={4}
          mb={4}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "column-reverse",
              md: "unset",
            },
            justifyContent: { xs: "center", sm: "center", md: "space-between" },
          }}
          alignItems="center"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "center", md: "start" },
              alignItems: "center",
              width: "100%",
              margin: { sm: "auto", md: 0 },
              maxWidth: "600px",
            }}
          >
            <Box sx={{ display: !modifiedList.length && !listLoader ? "block" : "none" }}>
              <Typography sx={{ marginTop: { xs: 3, sm: 3, md: 0 } }}>No List Found, please create a new list</Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{
                display: !modifiedList.length ? "none" : { xs: "none", sm: "none", md: "block" },
                letterSpacing: "0.2rem",
              }}
            >
              LIST
            </Typography>
            <Box
              width="100%"
              sx={{
                maxWidth: { xs: "auto", sm: "auto", md: "600px" },
                margin: { xs: "auto", sm: "auto", md: "0" },
                marginLeft: { xs: 0, sm: 0, md: 3 },
                marginTop: { xs: 3, sm: 3, md: 0 },
                display: !modifiedList.length ? "none" : { xs: "block", sm: "block", md: "flex" },
                alignItems: "center",
              }}
            >
              <CustomSelect options={modifiedList} selectedValue={selectedList} onSelectMenuItem={onSelectMenuItem} />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                sx={{
                  marginTop: { xs: 2, sm: 2, md: 0 },
                  marginLeft: { xs: 0, sm: 0, md: 2 },
                }}
                gap={1}
              >
                <Button size={isMobileView ? "small" : "medium"} onClick={onEditList}>
                  Edit
                </Button>
                <Button size={isMobileView ? "small" : "medium"} theme="danger" onClick={() => onDeleteList(singleList._id)}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
          <div>
            <Button action="add" size="medium" onClick={() => setCreateModal(true)}>
              Create new list
            </Button>
          </div>
        </Box>
      </Container>
      <Box className="users-list-container" sx={{ display: !modifiedList.length ? "none" : "block" }}>
        <Container maxWidth="lg">
          {singleListLoader ? (
            <Box textAlign="center" height="100px" display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} sx={{ color: defaultThemeColor }} />
            </Box>
          ) : null}

          {!singleListLoader && trackedUsers.length ? (
            <UserListCard
              data={trackedUsers}
              isMobileView={isMobileView}
              onButtonClick={onRemoveUserFromList}
            />
          ) : Array.isArray(trackedUsers) && trackedUsers.length === 0 && !singleListLoader ? (
            <Box textAlign="center" height="100px" display="flex" alignItems="center" justifyContent="center">
              No user added to this list.
            </Box>
          ) : null
          }
        </Container>
      </Box>

      <CustomModal title={`${isEdit ? "Edit" : "Create a new"} List`} open={createModal} onClose={() => setCreateModal(false)}>
        <Typography sx={{ padding: "0px 0px 5px 5px" }}>List Name</Typography>
        <input
          type="text"
          placeholder="Enter the List Name"
          style={{
            width: "100%",
            // color: "black",
            background: "black",
            border: `2px solid ${defaultThemeColor}`,
            outline: "none",
            borderRadius: "0px",
            padding: "12px",
            boxSizing: "border-box",
            fontSize: "1.3rem",
          }}
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
        <Box mt={4} display="flex" justifyContent="end">
          <Button size="medium" onClick={onSubmitCreateList}>
            {isEdit ? "Update" : "Create"}
          </Button>
        </Box>
      </CustomModal>
    </div>
  );
}

interface IUserListCard {
  data: any[];
  isMobileView: boolean;
  onButtonClick: (id: string) => void;
}

function UserListCard(props: IUserListCard) {
  const { data = [], isMobileView, onButtonClick } = props;

  return <Table className="universal-table">
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCell></TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Username</TableCell>
        <TableCell>Followers</TableCell>
        <TableCell>Tweets</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        Array.isArray(data) && data.map((item, index) => {
          return <TableRow key={index} onClick={e => e.stopPropagation()}>

            <TableCell><span style={{ color: defaultThemeColor }}>{index + 1}</span></TableCell>
            <TableCell><Avatar src={item.imageUrl || "/images/user-avatar.svg"} alt="" /></TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.username}</TableCell>
            <TableCell>{item.publicMetrics.followersCount}</TableCell>
            <TableCell>{item.publicMetrics.tweetCount}</TableCell>
            <TableCell>
              <Button size={"large"} sx={{ minWidth: isMobileView ? "auto" : "124px" }} onClick={() => onButtonClick(item._id)}>
                Remove
              </Button>
            </TableCell>
          </TableRow>
        })
      }
    </TableBody>
  </Table>
}
