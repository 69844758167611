import { useCallback, useEffect, useMemo, useState } from "react"
import Web3 from "web3"

const NetworkSymbol: any = {
    1: {
        networkName: 'ETH',
        chainId: '0x1',
        //   chainName: 'Binance Smart Chain',
        //   nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        //   rpcUrls: [process.env.REACT_APP_BSC_URL!],
        //   blockExplorerUrls: ['https://bscscan.com/']
    },
    56: {
        networkName: 'BSC',
        chainId: '0x38',
        // chainName: 'Binance Smart Chain',
        // nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        // rpcUrls: ['https://bsc-dataseed.binance.org/'],
        // blockExplorerUrls: ['https://bscscan.com/']
    },
    4: {
        networkName: 'RINKEBY',
        chainId: '0x4',
        // chainName: 'Binance Smart Chain',
        // nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
        // rpcUrls: ['https://bsc-dataseed.binance.org/'],
        // blockExplorerUrls: ['https://bscscan.com/'] 
    },
    137: {
        networkName: 'MATIC',
        chainId: '0x89',
        chainName: 'Matic Mainnet',
        nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com/']
    },
}

// chainName,nativeCurrency,rpcUrls,blockExplorerUrls 

const chainWithHexValues: any = {
    '0x1': 1,
    '0x38': 56,
    '0x4': 4,
    '0x89': 137
}

export const useWallet = () => {

    const [isMetamask, setIsMetamask] = useState(false)
    const [web3, setWeb3] = useState<any>(null)
    const [chainId, setChainId] = useState(0)
    const [isConnected, setIsConnected] = useState(false)
    const [ethereum, setEthereum] = useState<any>(undefined)
    const [address, setAddress] = useState('')
    const [accountBalance, setAccountBalance] = useState(0)
    const [wasWalletConnected, setWasWalletConnected] = useState(false)

    useEffect(() => {
        const { ethereum } = window as any
        if (ethereum && ethereum.isMetaMask) {
            setIsMetamask(true)
            const web3 = new Web3(ethereum);
            setWeb3(web3)
            setEthereum(ethereum)
            const autoConnect = sessionStorage.getItem('metamaskWallet')
            let wasWalletConnected = autoConnect && JSON.parse(autoConnect)
            wasWalletConnected = (wasWalletConnected && wasWalletConnected.connected) || false
            setWasWalletConnected(wasWalletConnected)
        }
    }, [])

    useEffect(() => {
        if (wasWalletConnected && ethereum && web3) {
            (async () => {
                const chainId = await web3.eth.net.getId();
                setChainId(chainId)
                onConnectToWallet(chainId)
            })()
        }
    }, [wasWalletConnected, ethereum, web3])


    const onConnectToWallet = async (requestedChainId = 1) => {
        try {
            if (!isMetamask) return alert('Install metamask')
            if (isConnected) return
            if (ethereum && isMetamask) {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                setAddress((accounts && accounts[0]) || '');
                sessionStorage.setItem('metamaskWallet', JSON.stringify({ connected: true }))
                setIsConnected(true)

                getUserBalance(accounts[0])
                onChangeNetwork(requestedChainId)

                ethereum.on('accountsChanged', function (accounts: any) {
                    const account = (accounts && accounts[0]) || ''
                    getUserBalance(account)
                    setAddress(account)
                })

                ethereum.on('chainChanged', function (chainHexId: any) {
                    const getChainId = chainWithHexValues[chainHexId]
                    const account = accounts[0]
                    getUserBalance(account)
                    setChainId(getChainId)
                })

            }
        } catch (ex: any) {
            throw new Error(ex.message)
        }
    }

    const shortAddress = useMemo(() => {
        let adrs = ''
        if (address) {
            const first = address.substring(0, 4)
            const middle = "******************"
            const last = address.substring(address.length - 4)
            adrs = first + middle + last
        }
        return adrs
    }, [address])

    const getUserBalance = useCallback(async (address) => {
        let balance = await web3.eth.getBalance(address)
        balance = web3.utils.fromWei(balance, 'ether')
        setAccountBalance(balance)
    }, [web3])

    const networkObj = useMemo(() => NetworkSymbol[chainId] || {}, [chainId]) // returns network obj

    const onChangeNetwork = async (requestedChainId: any) => {
        try {
            let networkObj = NetworkSymbol[requestedChainId] || null

            const chainId = await web3.eth.net.getId();
            if (requestedChainId === chainId) {
                return setChainId(chainId)
            }
            if (!networkObj) return
            delete networkObj.networkName
            try {
                await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ ...networkObj }],
                });
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                // console.log(switchError, "error")
                // if (switchError.code === 4902) {
                try {
                    const newConnectedResponse = await ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{ ...networkObj }],
                    })
                } catch (addError: any) {
                    // handle "add" error
                    throw new Error(addError.message)
                }
                // }
                // handle other "switch" errors
            }
        } catch (ex: any) {
            console.log(ex.message, "Error in onChangeNetwork")
        }
    }

    return {
        isMetamask,
        chainId,
        address,
        web3,
        isConnected,
        shortAddress,
        networkObj,
        accountBalance,
        onConnectToWallet,
        onChangeNetwork,
    }
}