import React from "react";
import ReactDOM from "react-dom/client";
import App from "./routes";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "./store";
import './app.scss'
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function getLibrary(provider: any) {
  return new Web3(provider);
}

root.render(
  // <React.StrictMode>

    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <App />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#ffffff",
              color: "#000000",
            },
            // Default options for specific types
            success: {
              duration: 3000,
              // theme: {
              //     primary: '#ffffff',
              //     secondary: 'black',
              // },
            },
          }}
        />
      </BrowserRouter>
  </Web3ReactProvider>
    </Provider>
  // </React.StrictMode>
);
