import Web3 from "web3"
import { APPLICATION_AUTH } from "./constants";

export function getAuthHeaders() {
    const auth = getObject(APPLICATION_AUTH) || {};
    if (auth && auth.token) {
        return {
            Authorization: `Bearer ${auth.token}`
        };
    }
    return {};
}

export const saveObject = (key, data) => {
    let obj = JSON.stringify(data);
    localStorage.setItem(key, obj);
};

export const removeObject = key => {
    localStorage.removeItem(key);
};

export const getObject = (key: string) => {
    return JSON.parse(localStorage.getItem(key)!);
};

export function createContract(contractAddress: string, abi: any) {
    const { ethereum } = window as any
    const web3 = new Web3(ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress)
    return contract
}

// default delay is 1 min
export const fakeDelay = (timer = 1000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true)
        }, timer)
    })
}