import { createSlice } from "@reduxjs/toolkit";
import { addUsersToList, createList, deleteList, getAllLists, getListById, removeUsersFromList, updateList } from "../actions/list";

interface IinitialState {
  lists: [];
  listCount: number;
  allListLoader: boolean;
  singleList: {};
  singleListLoader: boolean;
  addRemoveUserLoader: boolean;
  createUpdateLoader: boolean;
  deleteLoader: boolean;
}

const initialState: IinitialState = {
  lists: [],
  listCount: 0,
  allListLoader: false,
  singleList: {},
  singleListLoader: false,
  createUpdateLoader: false,
  addRemoveUserLoader: false,
  deleteLoader: false,
};

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    removeSingleList: (state) => {
      state.singleList = {};
      state.singleListLoader = false;
    },
  },
  extraReducers: {
    [getAllLists.pending.type]: (state) => {
      state.allListLoader = true;
    },
    [getAllLists.fulfilled.type]: (state, action) => {
      state.allListLoader = false;
      state.lists = action.payload.values;
      state.listCount = action.payload.total;
    },
    [getAllLists.rejected.type]: (state) => {
      state.allListLoader = false;
      state.lists = [];
      state.listCount = 0;
    },

    [getListById.pending.type]: (state) => {
      state.singleListLoader = true;
    },
    [getListById.fulfilled.type]: (state, action) => {
      state.singleListLoader = false;
      state.singleList = action.payload;
    },
    [getListById.rejected.type]: (state) => {
      state.singleListLoader = false;
      state.singleList = {};
    },

    [createList.pending.type]: (state) => {
      state.createUpdateLoader = true;
    },
    [createList.fulfilled.type]: (state) => {
      state.createUpdateLoader = false;
    },
    [createList.rejected.type]: (state) => {
      state.createUpdateLoader = false;
    },

    [updateList.pending.type]: (state) => {
      state.createUpdateLoader = true;
    },
    [updateList.fulfilled.type]: (state) => {
      state.createUpdateLoader = false;
    },
    [updateList.rejected.type]: (state) => {
      state.createUpdateLoader = false;
    },

    [deleteList.pending.type]: (state) => {
      state.deleteLoader = true;
    },
    [deleteList.fulfilled.type]: (state, action) => {
      console.log(action, "action")
      state.deleteLoader = false;
    },
    [deleteList.rejected.type]: (state) => {
      state.deleteLoader = false;
    },

    [addUsersToList.pending.type]: (state) => {
      state.addRemoveUserLoader = true;
    },
    [addUsersToList.fulfilled.type]: (state) => {
      state.addRemoveUserLoader = false;
    },
    [addUsersToList.rejected.type]: (state) => {
      state.addRemoveUserLoader = false;
    },

    [removeUsersFromList.pending.type]: (state) => {
      state.addRemoveUserLoader = true;
    },
    [removeUsersFromList.fulfilled.type]: (state) => {
      state.addRemoveUserLoader = false;
    },
    [removeUsersFromList.rejected.type]: (state) => {
      state.addRemoveUserLoader = false;
    },
  },
});

export const { removeSingleList } = listSlice.actions;
export default listSlice.reducer;
