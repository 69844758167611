import { Box, Grid, Typography } from "@mui/material";
import { DEFAULT_APPROVE_VALUE, bscTestNetPaymentContractAddress, defaultThemeColor, paymentTokenAddress } from "src/constants";
import Button from "src/shared/Button";
import Layout from "src/shared/Layout";
import PlansCardContainer from "./PlansCardContainer";
import CustomModal from "src/shared/Modal";
import { useState, useEffect } from "react"
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { useWallet } from "src/hooks/useWeb3";
import { useWeb3React } from "@web3-react/core";
import { useRef } from "react"
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { setLoader } from "src/redux/reducers/common";
import { injected } from "src/wallet/connectors";
import { createContract, fakeDelay } from "src/utils";
import PAYMENT_CONTRACT_ABI from '../../contracts/paymasterABI.json'
import TOKEN_CONTRACT_ABI from '../../contracts/payTokenABI.json'
import web3 from "web3"
import { getUserInfo } from "src/redux/actions/common";

export interface IplanOptions {
  id: number;
  name: string;
  features: string[];
  isRecommended: boolean;
  price: string;
  isActive: boolean,
}

let planOptions: IplanOptions[] = [
  {
    id: 1,
    name: "Basic",
    features: [
      "Up to 2 lists",
      "Up to 10 accounts in each list",
      // "Limited Notifica"
    ],
    isRecommended: false,
    price: "Free",
    isActive: true,
  },
  {
    id: 2,
    name: "Pro",
    features: [
      "Up to 15 lists",
      "Up to 50 accounts in each list",
    ],
    isRecommended: true,
    price: "$50/mo or $520/yr",
    isActive: false,
  },
];

const proPlanOptions = [
  {
    id: 0,
    duration: '1 Month',
    price: '50',
    currency: '$',
  },
  {
    id: 1,
    duration: '3 Months',
    price: '130',
    currency: '$',
  },
  {
    id: 2,
    duration: '12 Months',
    price: '520',
    currency: '$',
  },
]

enum PaymentType {
  FAIT = "fait",
  CRYPTO = "crypto",
}

export default function Subscribe() {

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedProOptionId, setSelectedProOption] = useState<number | null>(null)
  const [selectedPayment, setSelectedPayment] = useState<PaymentType | null>(null)
  const [selectedToken, setSelectedToken] = useState<string | null>(null)
  const [selectedChain, setSelectedChain] = useState<string | null>(null)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [modalLoader, setModalLoader] = useState<boolean>(true)
  const [isConnected, setIsConnected] = useState("initial")

  const { userData: user } = useAppSelector((state) => state.common);
  const { isProUser = false } = user || {}

  const dispatch = useAppDispatch();
  const { chainId = 0, activate, account, active, deactivate } = useWeb3React()
  const { isMetamask } = useWallet()

  const walletConnecting = useRef(false)

  useEffect(() => {
    (async () => {
      let wasConnected = sessionStorage.getItem("connected") as any
      wasConnected = wasConnected ? JSON.parse(wasConnected) : {}
      if (wasConnected?.connection) {
        connectToWallet()
      }

    })()
  }, [])

  useEffect(() => {
    if (!active && isConnected === "success" && !chainId) {
      toast.error("Chain not supported")
    }
  }, [active, isConnected, chainId])

  async function connectToWallet() {
    try {
      walletConnecting.current = true
      dispatch(setLoader({ action: true, message: 'Conneting to wallet please wait' }));
      setIsConnected("initial")
      await activate(injected)
      sessionStorage.setItem("connected", JSON.stringify({ connection: true }))
      setIsConnected("success")
      dispatch(setLoader({ action: false }));
    } catch (ex) {
      dispatch(setLoader({ action: false }));
      console.log(ex)
    }
  }

  const onCloseModal = async () => {
    setSelectedProOption(null)
    setSelectedPayment(null)
    setSelectedToken(null)
    setSelectedChain(null)
    setIsSuccess(false)
    setOpenModal(false)
    await fakeDelay(500)
    setModalLoader(true)
  }

  const onClickStart = async () => {
    setOpenModal(true)
    await fakeDelay(800)
    setModalLoader(false)
  }

  const onChangeProOption = async (optionId: number) => {
    setModalLoader(true)
    await fakeDelay(800)
    setSelectedProOption(optionId)
    setModalLoader(false)
  }

  const onChangePaymentType = async (paymentType: PaymentType) => {
    if (paymentType === PaymentType.CRYPTO) {
      if (!isMetamask) return toast.error('Metamask missing, please install')
      if (!active) connectToWallet()

      setSelectedPayment(paymentType)
    } else {
      toast('Please use crypto method for now.')
    }
  }

  const tokenAddresses = {
    'USDT': paymentTokenAddress,
    'USDC': paymentTokenAddress,
  }

  const paymentContractAddress = {
    'ETH': bscTestNetPaymentContractAddress,
    'BNB': bscTestNetPaymentContractAddress,
    'Polygon': bscTestNetPaymentContractAddress,
  }

  const onClickCryptoContinue = async () => {
    try {
      if (!selectedChain) return toast.error('Please select chain')
      if (!selectedToken) return toast.error('Please select token')
      if (!user?.twitterId || !account) return toast.error('Missing user details')


      const tokenAddress = tokenAddresses[selectedToken]
      const contractAddress = paymentContractAddress[selectedChain]

      console.log({ tokenAddress, contractAddress })
      const contractInstance = createContract(contractAddress, PAYMENT_CONTRACT_ABI)
      const tokenInstance = createContract(tokenAddress, TOKEN_CONTRACT_ABI)
      dispatch(setLoader({ action: true, message: 'Checking allowance' }))
      const tokenAllowance = await tokenInstance.methods.allowance(account, contractAddress).call()
      console.log(tokenAllowance, "tokenAllowance")
      if (tokenAllowance === "0") {
        dispatch(setLoader({ action: true, message: 'Please approve this contract' }))
        const args = [contractAddress, DEFAULT_APPROVE_VALUE]
        await tokenInstance.methods.approve(...args).send({ from: account })
      }
      console.log({ selectedProOptionId })
      dispatch(setLoader({ action: true, message: 'Please confirm transaction...' }))
      const args = [
        tokenAddress,
        selectedProOptionId,
        user?.twitterId
      ]
      await contractInstance.methods.paySubscription(...args).send({ from: account })

      // do API call to add transaction entry point with status "pending"
      // await contractInstance
      dispatch(setLoader({ action: true, message: 'Setting up your subscription...' }))
      await fakeDelay(1000)
      // wait for 2 sec

      dispatch(getUserInfo())
      setIsSuccess(true)
      dispatch(setLoader({ action: false }))

    } catch (ex: any) {
      console.log(ex.message, "exx")
      dispatch(setLoader({ action: false }))
      toast.error(ex.message || 'Something went wrong')
    }
  }

  const selectedPlan = proPlanOptions.find(item => item.id === selectedProOptionId)

  planOptions = planOptions.map(item => {
    if (isProUser && item.id === 2) return { ...item, isActive: true }
    if (isProUser && item.id === 1) return { ...item, isActive: false }
    return item
  })

  return (
    <Layout>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h5"
          sx={{ textAlign: "center", letterSpacing: "0.7rem", fontSize: "2rem" }}
        >
          CHOOSE YOUR PLAN
        </Typography>
        <Typography
          noWrap
          sx={{
            width: "fit-content",
            textAlign: "center",
            background: defaultThemeColor,
            color: "black",
            borderRadius: 0,
            padding: "4px 16px",
            margin: "auto",
            marginTop: 3
          }}
        >
          Save up to 20% off on Annual Plans
        </Typography>
      </Box>

      <Box maxWidth="900px" width="100%" margin="auto" mt={8}>
        <PlansCardContainer planOptions={planOptions} onClickStart={onClickStart} />
      </Box>

      <CustomModal open={openModal} maxWidth="md" showClose onClose={onCloseModal}>
        {modalLoader ? <Box height="300px" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress style={{ color: defaultThemeColor }} size={28} />
        </Box> :
          <>
            {selectedProOptionId === null && <SelectProOptions setSelectedProOption={onChangeProOption} />}
            {(selectedProOptionId !== null && !selectedPayment) && <PaymentOptions setSelectedPayment={onChangePaymentType} selectedPlan={selectedPlan} />}
            {(selectedPayment === PaymentType.CRYPTO && !isSuccess) && <SelectPaymentChain
              selectedToken={selectedToken}
              selectedChain={selectedChain}
              setSelectedToken={setSelectedToken}
              setSelectedChain={setSelectedChain}
              onClickContinue={onClickCryptoContinue}
            />}
            {isSuccess && <PaymentSuccess />}
          </>
        }
      </CustomModal>

    </Layout>
  );
}

function SelectProOptions(props: {
  setSelectedProOption: (n: number) => void
}) {
  const { setSelectedProOption } = props
  return <Grid container>
    <Grid item xs={12}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h2" letterSpacing="3px">Pro Plan</Typography>
        <Typography variant="h5">Subscription Options</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent="center">
        {
          proPlanOptions.map(option => {
            return <Grid item xs={12} sm={6} md={4} key={option.id}>
              <Box textAlign="center" mt={2}>
                <Typography variant="h3">{option.duration}</Typography>
                <Typography variant="h4" sx={{ marginTop: 3, color: defaultThemeColor }}>{option.currency}{option.price}</Typography>
                <Button sx={{ width: "60%", marginTop: 3 }} size="medium" onClick={() => setSelectedProOption(option.id)}>Select</Button>
              </Box>
            </Grid>
          })
        }
      </Grid>
    </Grid>
  </Grid>
}


function PaymentOptions(props: {
  setSelectedPayment: (s: PaymentType) => void
  selectedPlan: any
}) {
  const { setSelectedPayment, selectedPlan } = props
  return <Grid container>
    <Grid item xs={12}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h2" letterSpacing="3px">Pro Plan</Typography>
        <Typography variant="h5">{selectedPlan?.duration} ( {selectedPlan?.currency}{selectedPlan?.price} )</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent="center">
        {/* <Grid item xs={12} sm={6} textAlign="center">
          <Box
            margin="auto"
            width="250px"
            height="220px"
            border={`2px solid ${defaultThemeColor}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => setSelectedPayment(PaymentType.FAIT)}
          >
            <div>
              <img src="/icons/dollar-icon.svg" alt="" />
              <Typography sx={{ marginTop: 2 }} variant="h4" lineHeight="1">Pay with <br />Fiat</Typography>
            </div>
          </Box>
        </Grid> */}

        <Grid item xs={12} sm={6} textAlign="center">
          <Box
            margin="auto"
            width="250px"
            height="220px"
            border={`2px solid ${defaultThemeColor}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => setSelectedPayment(PaymentType.CRYPTO)}
          >
            <div>
              <img src="/icons/bitcoin-icon.svg" alt="" />
              <Typography sx={{ marginTop: 2 }} variant="h4" lineHeight="1" >Pay with <br />Crypto</Typography>
            </div>
          </Box>
        </Grid>

      </Grid>
    </Grid>
  </Grid >
}

function SelectPaymentChain(props: {
  selectedToken: any
  selectedChain: any
  setSelectedToken: (s: string) => void
  setSelectedChain: (s: string) => void
  onClickContinue: () => void
}) {
  const {
    selectedToken,
    selectedChain,
    setSelectedToken,
    setSelectedChain,
    onClickContinue
  } = props



  return <Grid container>
    <Grid item xs={12}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h2" letterSpacing="3px">Pay with Crypto</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>

      <Box mt={4}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Typography style={{ fontSize: '1.8rem', textAlign: 'center' }}>Currency options</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box display="flex">
              {
                ['USDT', 'USDC'].map(token => {
                  return <Typography
                    key={token}
                    style={{
                      fontSize: '1.6rem',
                      marginRight: '20px',
                      border: `2px solid ${defaultThemeColor}`,
                      padding: '2px 10px',
                      maxWidth: '80px',
                      minWidth: '60px',
                      width: '100%',
                      textAlign: 'center',
                      cursor: 'pointer',
                      background: `${selectedToken === token ? defaultThemeColor : ''}`,
                      color: `${selectedToken === token ? '#000000' : ''}`,
                    }}
                    onClick={() => setSelectedToken(token)}
                  >
                    {token}</Typography>
                })
              }

            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid container >
          <Grid item xs={12} sm={12} md={6}>
            <Typography style={{ fontSize: '1.8rem', textAlign: 'center' }}>Chain</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box display="flex">
              {
                ['ETH', 'BNB', 'Polygon'].map(chain => {
                  return <Typography
                    key={chain}
                    style={{
                      fontSize: '1.6rem',
                      marginRight: '20px',
                      border: `2px solid ${defaultThemeColor}`,
                      padding: '2px 10px',
                      maxWidth: '80px',
                      minWidth: '60px',
                      width: '100%',
                      textAlign: 'center',
                      cursor: 'pointer',
                      background: `${selectedChain === chain ? defaultThemeColor : ''}`,
                      color: `${selectedChain === chain ? '#000000' : ''}`,
                    }}
                    onClick={() => setSelectedChain(chain)}
                  >
                    {chain}</Typography>
                })
              }
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={6} textAlign="center">
        <Button size="medium" sx={{ width: '250px' }} onClick={onClickContinue}>Continue</Button>
      </Box>

    </Grid>
  </Grid>
}

function PaymentSuccess() {
  return <Grid container>
    <Grid item xs={12} textAlign="center">
      <Typography variant="h2" letterSpacing="3px">Success!</Typography>
      <Box mt={3} mb={4}>
        <img src="/icons/success-icon.svg" alt="" />
      </Box>
      <Typography variant="h4">Your subscription is now active</Typography>
    </Grid>
  </Grid>
}