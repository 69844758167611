import axios from "axios";
import { toast } from "react-hot-toast";
import { getAuthHeaders } from "src/utils";

export const getAllListAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v1/list", { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListByIdAPI = (listId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/v1/list/${listId}`, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createListAPI = (data: { name: string }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/v1/list`, data, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        console.log(res, "createListAPI")
        resolve(res.data);
      })
      .catch((err) => {
        toast.error(err && err.response && err.response.data && err.response.data.message || 'Something went wrong')
        reject(err);
      });
  });
};

export const updateListAPI = (listId: string, data: { name: string }) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/v1/list/${listId}`, data, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteListAPI = (listId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/v1/list/${listId}`, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addUsersToListAPI = (listId: string, data: string[]) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/v1/list/${listId}/add`, { userIds: data }, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        toast.error(err && err.response && err.response.data && err.response.data.message || 'Something went wrong')
        reject(err);
      });
  });
};

export const removeUsersFromListAPI = (listId: string, data: string[]) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/v1/list/${listId}/remove`, { userIds: data }, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
