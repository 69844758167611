import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./redux/reducers/common";
import listReducer from "./redux/reducers/list";
import thunk from "redux-thunk"

export const store = configureStore({
  reducer: {
    common: commonReducer,
    list: listReducer
  },
  // middleware: [thunk]
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
