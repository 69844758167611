import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Slide from '@mui/material/Slide';
import Zoom from "@mui/material/Zoom";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Typography } from "@mui/material";
import { defaultThemeColor } from "src/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export default function CustomModal(props) {
  const { title = "", maxWidth= "xs", open = false, onClose = () => {}, showClose = false, disableOutsideClose = false } = props;

  const onHandleClose = () => {
    if (disableOutsideClose) return;
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onHandleClose}
        fullWidth
        maxWidth={maxWidth}
        PaperProps={{
          style: { borderRadius: "0px", border: `3px solid ${defaultThemeColor}` },
        }}
        sx={{ background: "#00000091" }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ padding: "32px 24px 48px" }} boxSizing="border-box">
          <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 1, position: "relative" }}>
            {title}
          </Typography>
          {showClose ? (
            <span
              onClick={onClose}
              style={{
                position: "absolute",
                top: "0px",
                right: "6px",
                fontSize: "26px",
                fontWeight: "600",
                color: "grey",
                padding: "4px 12px",
                cursor: "pointer",
              }}
            >
              x
            </span>
          ) : null}
          {props.children}
        </Box>
      </Dialog>
    </div>
  );
}
