import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, CircularProgress, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import Button from "src/shared/Button";
import SearchUser from "src/shared/SearchUser";
import "./style.scss";
import { mostTrackedUsers } from "src/redux/actions/common";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { addUsersToList, getAllLists, getListById } from "src/redux/actions/list";
import { toast } from "react-hot-toast";
import CustomModal from "src/shared/Modal";
import { setLoader } from "src/redux/reducers/common";
import { defaultThemeColor } from "src/constants";

export default function Homepage() {
  const isMobileView = useMediaQuery("(max-width:600px)");
  const dispatch = useAppDispatch();
  const [twitterId, setTwitterId] = useState<string>("");
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);

  // redux states
  const mostTrackedAccounts = useAppSelector((state) => state.common.mostTrackedUsers);

  const loader = useAppSelector((state) => state.common.mostTrackedUserLoader);
  const addRemoveUserLoader = useAppSelector((state) => state.list.addRemoveUserLoader) as boolean;
  const lists = useAppSelector((state) => state.list.lists);
  const listLoader = useAppSelector((state) => state.list.allListLoader) as boolean;
  const isLoggedIn = useAppSelector((state) => state.common.isLoggedIn);

  // handle loaders
  useEffect(() => {
    if (addRemoveUserLoader) {
      dispatch(setLoader({ action: true }));
    } else {
      dispatch(setLoader({ action: false }));
    }
  }, [addRemoveUserLoader]);

  const onGetUserList = () => {
    if (!isLoggedIn) return;
    if (!lists.length) {
      dispatch(getAllLists());
    }
  };

  useEffect(() => {
    if (!mostTrackedAccounts.length) {
      dispatch(mostTrackedUsers());
    }
  }, []);

  const onClickAddUser = (twitterId: string) => {
    if (!isLoggedIn) return toast.error("Please login first to add user.");
    setTwitterId(twitterId);
    setOpenAddModal(true);
    onGetUserList();
  };

  const onClickCloseModal = () => {
    setOpenAddModal(false);
    setTwitterId("");
  };

  const onClickListItem = (listId: string) => {
    onAddUserToList(listId);
  };

  const onAddUserToList = (listId: string) => {
    const userIds = [twitterId];
    onClickCloseModal()
    dispatch(addUsersToList({ listId: listId, data: userIds })).then((res: any) => {
      if (typeof res === "object" && res.hasOwnProperty('error') && res.error && res.error.name === "AxiosError") {
        return
      }
      toast.success("User added to the list");
    });
  };

  return (
    <div className="homepage-container">
      <Container maxWidth="lg">
        <Box mt={4}>
          <Typography
            variant="h4"
            sx={{
              letterSpacing: "0.2rem",
              color: "white",
              textAlign: 'center'
            }}
          >
            Discover, track, and follow all Twitter accounts in one place
          </Typography>
          <Box mt={4}>
            <SearchUser onAddUser={onClickAddUser} />
          </Box>
          <Box mt={4}>
            {Array.isArray(mostTrackedAccounts) && !mostTrackedAccounts.length && loader ? (
              <Box mt={16} mb={16} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={30} sx={{ color: defaultThemeColor }} />
              </Box>
            ) : null}
            {mostTrackedAccounts.length ? (
              <TrackedAccountCards
                data={mostTrackedAccounts}
                isMobileView={isMobileView}
                onButtonClick={onClickAddUser}
              />
            ) : null}
          </Box>
        </Box>
      </Container>

      <CustomModal title="Select List to add" open={openAddModal} onClose={onClickCloseModal}>
        {lists && lists.length && !listLoader ? (
          <Box mt={4} mb={4}>
            {lists.map((list: any, index: number) => {
              return (
                <Box key={index} m={2} p={2} display="flex" justifyContent="space-between" bgcolor="rgba(212, 251, 95, 0.15)" borderRadius={0}>
                  <Typography>{list.name}</Typography>
                  <Button size="small" onClick={() => onClickListItem(list._id)}>
                    Add
                  </Button>
                </Box>
              );
            })}
          </Box>
        ) : listLoader ? (
          <Box mt={4} mb={4} textAlign="center">
            <CircularProgress size={30} sx={{ color: defaultThemeColor }} />
          </Box>
        ) : (
          <Box mt={4} mb={4} textAlign="center">
            No list found.
          </Box>
        )}
      </CustomModal>
    </div>
  );
}

interface ITrackedAccountCarts {
  data: any[],
  isMobileView: boolean
  onButtonClick: any
}

export function TrackedAccountCards(props: ITrackedAccountCarts) {
  const { data = [], isMobileView, onButtonClick } = props;

  return <Table className="universal-table">
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCell></TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Username</TableCell>
        <TableCell>Followers</TableCell>
        <TableCell>Tweets</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {
        Array.isArray(data) && data.map((item, index) => {
          return <TableRow key={index} onClick={e => e.stopPropagation()}>

            <TableCell><span style={{ color: defaultThemeColor }}>{index + 1}</span></TableCell>
            <TableCell><Avatar src={item.imageUrl || "/images/user-avatar.svg"} alt="" /></TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.username}</TableCell>
            <TableCell>{item.publicMetrics.followersCount}</TableCell>
            <TableCell>{item.publicMetrics.tweetCount}</TableCell>
            <TableCell>
              <Button action="add" size={isMobileView ? "small" : "medium"} sx={{ minWidth: isMobileView ? "auto" : "124px" }} onClick={() => onButtonClick(item.twitterId)}>
                {isMobileView ? "Add" : "Add to list"}
              </Button>
            </TableCell>
          </TableRow>
        })
      }
    </TableBody>
  </Table>
}
