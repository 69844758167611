import { useState, useMemo } from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import { Avatar, Box, ClickAwayListener, Tooltip, Typography, Zoom, useMediaQuery } from "@mui/material";
import queryString from "query-string";
import { useEffect } from "react";
import { requestTokenAPI } from "src/redux/apis/auth";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { IUserWithToken } from "./type";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { removeUserData, saveUserData, setLoader } from "src/redux/reducers/common";
import { APPLICATION_AUTH, defaultThemeColor, telegramBotUsername } from "src/constants";
import CustomModal from "src/shared/Modal";
import { getUserInfo } from "src/redux/actions/common";

const navLinks = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "My Lists",
    path: "/lists",
  },
  {
    label: "Subscribe",
    path: "/subscribe",
  },
];

function Header() {
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const [openInfo, setOpenInfo] = useState<boolean>(false)

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isMobileView = useMediaQuery("(max-width:600px)");

  //reducer
  const { userData: user, isAppLoading = false, isLoggedIn = false } = useAppSelector((state) => state.common);
  const { isProUser = false } = user || {}

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserInfo())
    }
  }, [isLoggedIn])

  useEffect(() => {
    const parsed = queryString.parse(search) as { token: string };
    if (!!parsed.token) {
      const decoded = jwt_decode(parsed.token) as any;
      const userData = { token: parsed.token, ...decoded } as IUserWithToken;
      dispatch(saveUserData(userData));
      window.history.replaceState(null, "Twitter bot", "/");
    } else {
      const localData = localStorage.getItem(APPLICATION_AUTH);
      if (localData) {
        const userData = JSON.parse(localData);
        dispatch(saveUserData(userData));
      }
    }
  }, [search]);

  const onClickLogin = async () => {
    try {
      if (isAppLoading) return;
      dispatch(setLoader({ action: true, message: "requesting token from twitter..." }));
      const requestedAuth = (await requestTokenAPI()) as { token: string };
      window.open(`https://api.twitter.com/oauth/authorize?${requestedAuth.token}`, "_self");
    } catch (ex) {
      dispatch(setLoader({ action: false }));
    }
  };

  const onClickLogout = () => {
    dispatch(setLoader({ action: true }));
    setTimeout(() => {
      dispatch(removeUserData());
      dispatch(setLoader({ action: false }));
    }, 500);
  };

  const filterdNavLinks = useMemo(() => {
    if(isProUser) return navLinks.filter(item => item.path !== '/subscribe')
    return navLinks
  }, [isProUser])

  return (
    <div className="header-component-main">
      <Box
        sx={{
          height: { xs: "65%", sm: "65%", md: "100%" },
        }}
        className="header-item"
      >
        <Box className="logo-container">
          <Link to="/">
            {/* <div className="header-logo">
              <Avatar
                sx={{
                  width: { xs: "35px", sm: "40px", md: "auto" },
                  height: { xs: "35px", sm: "40px", md: "auto" },
                }}
                variant="square"
                src="/images/header-logo.svg"
                alt="logo"
              />
            </div> */}
            <Box className="main-heading">
              {/* <Typography
                sx={{
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.3rem", lg: "3rem" },
                }}
              >
                Twitter Follower Bot
              </Typography> */}
              <img src="/images/logo.svg" alt='' width='200px' />
            </Box>
          </Link>
        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }} className="navigation-container">
          <ul className="nav-list-container">
            {filterdNavLinks.map((link) => {
              const isActive = pathname === link.path;
              return (
                <li key={link.path}>
                  <Link to={link.path} className="nav-item">
                    <Typography
                      noWrap
                      sx={{
                        width: "fit-content",
                        // background: isActive ? "#03A9F4" : "",
                        fontSize: 18,
                        color: "#ffffff",
                        border: isActive ? `2px solid ${defaultThemeColor}` : "2px solid transparent",
                        padding: "0px 16px",
                        ":visited, :hover, :active": {
                          color: "#ffffff",
                        },
                      }}
                    >
                      {link.label}
                    </Typography>
                  </Link>
                </li>
              );
            })}
          </ul>
        </Box>
        <Box
          sx={{
            maxWidth: { sm: "auto", md: isLoggedIn ? "180px" : "130px" },
            width: { sm: "auto", md: "100%" },
            paddingRight: { xs: "20px", sm: "20px", md: 0 },
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {isLoggedIn ? (
            <>
              <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, position: "relative", cursor: "pointer", justifyContent: "center" }}
                  onClick={() => setOpenMenu((prev) => !prev)}
                >
                  <Avatar src={user.imageUrl} alt="user image" />
                  <Typography sx={{ display: { xs: "none", sm: "block" } }}>{user.username}</Typography>
                  {openMenu && (
                    <div className="user-header-dropdown">
                      <ul>
                        <li>
                          <Link to="settings">Settings</Link>
                        </li>
                        <li onClick={onClickLogout}>
                          <span>logout</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </Box>
              </ClickAwayListener>
              {/* <Typography variant="caption" onClick={onClickLogout}>logout</Typography> */}
            </>
          ) : (
            <Typography
              sx={{
                cursor: "pointer",
                textAlign: "center",
                borderRadius: 2,
                border: "1px solid grey",
                padding: "5px 10px",
                width: "fit-content",
                margin: "auto",
              }}
              onClick={onClickLogin}
            >
              Login
            </Typography>
          )}
          <Box
            ml={2}
            bgcolor={defaultThemeColor}
            borderRadius={2}
            height="17px"
            width="fit-content"
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenInfo(true)}
          >
            <Tooltip TransitionComponent={Zoom} title="Pikatweet bot Instructions" sx={{ color: 'black', background: 'white' }}>
              <img src='/icons/info-icon.svg' />
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          height: "35%",
          background: defaultThemeColor,
        }}
      >
        <ul className="nav-list-container-mobile">
          {navLinks.map((link) => {
            const isActive = pathname === link.path;
            return (
              <li key={link.path}>
                <Link to={link.path} className="nav-item-mobile">
                  <Typography
                    noWrap
                    sx={{
                      width: "fit-content",
                      background: isActive ? "#000000" : "",
                      color: isActive ? "#fff" : "#000",
                      borderRadius: 0,
                      padding: "0px 10px",
                      ":visited, :hover, :active": {
                        color: isActive ? "" : "#000",
                      },
                    }}
                  >
                    {link.label}
                  </Typography>
                </Link>
              </li>
            );
          })}
        </ul>
      </Box>

      <CustomModal
        open={openInfo}
        maxWidth='sm'
        onClose={() => setOpenInfo(false)}
      >
        <Box pl="6%" position="relative">
          <span
            onClick={() => setOpenInfo(false)}
            style={{
              color: defaultThemeColor,
              position: 'absolute',
              top: '-16px',
              right: '0px',
              fontSize: '25px',
              cursor: 'pointer'
            }}>
            X</span>
          <Box><Typography variant="h4" textAlign='center'>How to use the <span style={{ color: defaultThemeColor }}>Pikatweet</span> Bot</Typography></Box>

          <Box mt={4}>
            <span style={{ border: `2px solid ${defaultThemeColor}`, padding: '5px 20px' }}>Step 1</span>
          </Box>

          <Box mt={4} display='flex' justifyContent="center">
            <div style={{ display: 'flex', marginLeft: '50px' }}>
              <img src='/icons/step1-icon.svg' />
              <span style={{ maxWidth: 250, marginLeft: '30px', fontSize: 18 }}>Login using your Twitter account and authorise the app</span>
            </div>
          </Box>

          <Box mt={4}>
            <span style={{ border: `2px solid ${defaultThemeColor}`, padding: '5px 20px' }}>Step 2</span>
          </Box>

          <Box mt={4} display='flex' justifyContent="center">
            <div style={{ display: 'flex', marginLeft: '50px' }}>
              <img src='/icons/step2-icon.svg' />
              <span style={{ maxWidth: 250, marginLeft: '30px', fontSize: 18 }}>Go to <a href="/settings" target="_blank">Settings</a> on the website and generate chat token</span>
            </div>
          </Box>

          <Box mt={4}>
            <span style={{ border: `2px solid ${defaultThemeColor}`, padding: '5px 20px' }}>Step 3</span>
          </Box>

          <Box mt={4} display='flex' justifyContent="center">
            <div style={{ display: 'flex', marginLeft: '50px' }}>
              <img src='/icons/step3-icon.svg' />
              <span style={{ maxWidth: 250, marginLeft: '30px', fontSize: 18 }}>Go to the <a href={`https://telegram.me/${telegramBotUsername}`} target="_blank"><span>Pikatweet TG bot</span></a> using the link, use the command /addtoken and enter the chat token which was generated</span>
            </div>
          </Box>

          <Box mt={4}>
            <span style={{ border: `2px solid ${defaultThemeColor}`, padding: '5px 20px' }}>Step 4</span>
          </Box>

          <Box mt={4} display='flex' justifyContent="center">
            <div style={{ display: 'flex', marginLeft: '50px' }}>
              <img src='/icons/step4-icon.svg' />
              <span style={{ maxWidth: 250, marginLeft: '30px', fontSize: 18 }}>Create different lists in the <a href="/lists" target="_blank"><span>MyLists</span></a> page, and track the follows of multiple Twitter users. Happy tracking!</span>
            </div>
          </Box>

        </Box>
      </CustomModal>
    </div >
  );
}

export default Header;
