import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { defaultThemeColor } from "src/constants";
import { useAppSelector } from "src/hooks/redux";
import Layout from "src/shared/Layout";
import TelegramSetting from "./TelegramSetting";
import SubscriptionSetting from "./SubscriptionSetting";
import "./style.scss"

function Settings() {
  const [tabIndex, setTabIndex] = useState<number>(1);
  const isLoggedIn = useAppSelector((state) => state.common.isLoggedIn);


  if (!isLoggedIn) {
    return (
      <Layout>
        <Box className="login-description" mt={12}>
          Please login to use this feature
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="settings-container">
        <Grid container className="settings-list-container">
          <Grid item xs={12}>
            <Box mb={3}>
              <Typography variant="h3">Settings</Typography>
            </Box>
          </Grid>
          <Grid item xs={2} md={4} lg={3.5} sx={{ overflow: "hidden" }}>
            <ul className="list-container">
              <li className={tabIndex === 1 ? "active" : "inactive"} onClick={() => setTabIndex(1)}>
                Telegram Settings
              </li>
              <li className={tabIndex === 2 ? "active" : "inactive"} onClick={() => setTabIndex(2)}>
                Manage your Subscription
              </li>
            </ul>
          </Grid>

          <Grid item xs={10} md={8} lg={8.5}>
            <Box p={2} sx={{paddingLeft: { xs: 2, sm: '8%' }}} border={`2px solid ${defaultThemeColor}`} height="50vh">
              {
                tabIndex === 1 ? <TelegramSetting /> : <SubscriptionSetting />
              }
            </Box>
          </Grid>

        </Grid>

      </div>
    </Layout>
  );
}

export default Settings;
