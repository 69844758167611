import axios from "axios";
import { getAuthHeaders } from "src/utils";

export const requestTokenAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v1/user/request_token")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const searchTwitterUserAPI = (usernames: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v1/twitter/search/user", { params: { usernames }, headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkForChatTokenAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v1/user/chat_token", { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const generateChatTokenAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .post("/v1/user/chat_token/generate", {}, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeChatTokenAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .post("/v1/user/chat_token/remove", {}, { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const mostTrackedUsersAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v1/list/most_tracked", { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserInfoAPI = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/v1/user/get/me", { headers: { ...getAuthHeaders() } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
