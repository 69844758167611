import { useState } from "react";
import { Avatar, Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import Button from "src/shared/Button";
import "./style.scss";
import { searchTwitterUser } from "src/redux/actions/common";
import { defaultThemeColor } from "src/constants";
import { removeSearchAccountData } from "src/redux/reducers/common";

interface ISearchUser {
  // searchTitle?: string;
  onAddUser?: (twitterId: string) => void;
}
interface ITwitterUser {
  url: string;
  id: string;
  profile_image_url: string;
  description: string;
  public_metrics: {
    followers_count: number;
    following_count: number;
    tweet_count: number;
    listed_count: number;
  };
  created_at: string;
  username: string;
  protected: boolean;
  verified: boolean;
  name: string;
}

export default function SearchUser(props: ISearchUser) {
  const { onAddUser } = props;

  const [input, setInput] = useState<string>("");
  const [isSearchDone, setIsSearchDone] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const twitterUser = useAppSelector((state) => state.common.twitterUser) as ITwitterUser;
  const twitterUserLoader = useAppSelector((state) => state.common.twitterUserLoader);
  const isLoggedIn = useAppSelector((state) => state.common.isLoggedIn);

  const onClickSearchUser = async () => {
    if (input && isLoggedIn) {
      await dispatch(searchTwitterUser(input));
      setIsSearchDone(true);
      // window.scrollTo(0, document.body.scrollHeight);
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" && !twitterUserLoader) {
      onClickSearchUser();
    }
  };

  const onChangeHandler = (value) => {
    setIsSearchDone(false);
    setInput(value);
  };

  const onClearSearchHistory = () => {
    setInput('')
    dispatch(removeSearchAccountData())
  }

  return (
    <div className="search-user-container-main">
      <Box
        sx={{
          // display: { sm: "block", md: "flex" },
          // justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          mb: 4,
          border: `10px solid ${defaultThemeColor}`
        }}
      >
        <Box boxSizing="border-box" width="100%" sx={{ margin: { xs: "auto", sm: "auto", md: 0 } }}>
          <Box className="search-input-container" sx={{ margin: { xs: "auto", sm: "auto", md: "0" } }}>
            <input
              type="text"
              placeholder="Search by Twitter username"
              onChange={(e) => onChangeHandler(e.target.value)}
              value={input}
              onKeyDown={onKeyPress}
            />
            <img src="/images/search-icon.svg" alt="search" onClick={onClickSearchUser} />
          </Box>
        </Box>
        {twitterUserLoader ? (
          <Box pt={4} pb={4} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={30} sx={{ color: defaultThemeColor }} />
          </Box>
        ) : null}

        {Object.keys(twitterUser).length && !twitterUserLoader && isLoggedIn ? (
          <Box pt={2} pb={2} position="relative">
            <div className="search-clear-icon"><span onClick={onClearSearchHistory}>x</span></div>
            <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: 'center' }}>
                <Avatar src={twitterUser?.profile_image_url} />
                <div style={{ marginLeft: 30 }}>
                  <Typography sx={{ textAlign: "start", fontSize: "1.5rem" }}>{twitterUser?.name}</Typography>
                  <Typography sx={{ textAlign: "start", fontSize: "1.3rem", color: defaultThemeColor }}>@{twitterUser?.username}</Typography>
                </div>
              </Box>
              <div style={{ width: 300 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600 }}>Followers:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ textAlign: "start", color: defaultThemeColor }}>{twitterUser?.public_metrics?.followers_count}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600 }}>Following:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ textAlign: "start", color: defaultThemeColor }}>{twitterUser?.public_metrics?.following_count}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600 }}>Bio:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      title={twitterUser?.description}
                      sx={{ textAlign: "start", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: defaultThemeColor }}
                    >
                      {twitterUser?.description}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Button action="add" size="medium" sx={{ maxHeight: "40px" }} onClick={() => onAddUser!(twitterUser?.id)}>Add to List</Button>
            </Box>
          </Box>) : !twitterUserLoader && isSearchDone && input && isLoggedIn ? (
            <Box className="login-description">User doesn't exists with this Username</Box>
          ) : !isLoggedIn && input ? (
            <Box className="login-description">Please login to fetch users from twitter</Box>
          ) : null}

      </Box>
    </div>
  );
}
