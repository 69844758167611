import { useState } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import "./style.scss";
import { useAppDispatch } from "src/hooks/redux";

function CustomSelect(props: any) {
  const { options = [], selectedValue = {}, onSelectMenuItem = () => { } } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };


  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className="custom-select-container" sx={{ position: "relative", width: "100%" }}>
        <div onClick={handleClick} className="dropdown-trigger-el">
          <span className={`selected-list-label ${selectedValue?.label ? "" : "not-selected"}`}>{selectedValue?.label || "Select..."}</span>
          <img src="/images/arrow-down-black.svg" alt="arrow down" width="32px" />
        </div>
        {open ? (
          <Box className="dropdown-menu">
            <ul>
              {options.map((item, index) => {
                const isSelected = item.value === selectedValue?.value;
                return (
                  <li
                    key={index}
                    className={`${isSelected ? "selected" : "not-selected"}`}
                    onClick={() => {
                      onSelectMenuItem(item);
                      handleClickAway();
                    }}
                  >
                    {item.label}
                  </li>
                );
              })}
            </ul>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
}

export default CustomSelect;
