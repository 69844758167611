import { Container, Box } from "@mui/material";
import "./style.scss";

interface ILayout {
  mt?: 2 | 4 | 6;
  children: React.ReactNode;
}

function Layout(props: ILayout) {
  const { mt = 4 } = props;
  return (
    <Container className="main-layout-container" maxWidth="lg">
      <Box mt={mt}>{props.children}</Box>
    </Container>
  );
}

export default Layout;
