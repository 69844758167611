import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserWithToken } from "src/components/Header/type";
import { APPLICATION_AUTH } from "src/constants";
import { checkForChatToken, generateChatToken, getUserInfo, mostTrackedUsers, removeChatToken, searchTwitterUser } from "../actions/common";

interface IinitialState {
  isAppLoading: boolean;
  appLoaderMsg: string;
  userData: IUserWithToken;
  isLoggedIn: boolean;
  twitterUser: {};
  twitterUserLoader: boolean;
  chatTokenLoader: boolean
  mostTrackedUsers: any[];
  mostTrackedUserLoader: boolean
}

const intitalUserData = {
  id: "",
  name: "",
  username: "",
  twitterId: "",
  profileUrl: "",
  imageUrl: "",
  token: "",
  isProUser: false,
  subDurationinMonths: 0,
  subStartDate: 0,
  subEndDate: 0
};

const initialState: IinitialState = {
  isAppLoading: false,
  appLoaderMsg: "",
  userData: { ...intitalUserData },
  isLoggedIn: false,
  twitterUser: {},
  twitterUserLoader: false,
  chatTokenLoader: false,
  mostTrackedUsers: [],
  mostTrackedUserLoader: false,

};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<{ action: boolean; message?: string }>) => {
      state.isAppLoading = action.payload.action;
      if (!state.isAppLoading) state.appLoaderMsg = ""
      else state.appLoaderMsg = action.payload.message || "";
    },
    saveUserData: (state, action: PayloadAction<IUserWithToken>) => {
      localStorage.setItem(APPLICATION_AUTH, JSON.stringify(action.payload));
      state.userData = action.payload;
      state.isLoggedIn = true;
    },
    removeUserData: (state) => {
      state.userData = { ...intitalUserData };
      localStorage.removeItem(APPLICATION_AUTH);
      state.isLoggedIn = false;
    },
    removeSearchAccountData: (state) => {
      state.twitterUser = {}
    }
  },
  extraReducers: {

    [getUserInfo.fulfilled.type]: (state, action) => {
      const userInfo = action.payload && action.payload || {}
      state.userData = {
        ...state.userData,
        isProUser: userInfo.isProUser,
        subDurationinMonths: userInfo.subDurationinMonths,
        subStartDate: userInfo.subStartDate,
        subEndDate: userInfo.subEndDate,
      }
    },
    [searchTwitterUser.pending.type]: (state) => {
      state.twitterUserLoader = true;
    },
    [searchTwitterUser.fulfilled.type]: (state, action) => {
      state.twitterUserLoader = false;
      state.twitterUser = (action.payload && action.payload.values && action.payload.values[0]) || {};
    },
    [searchTwitterUser.rejected.type]: (state) => {
      state.twitterUserLoader = false;
      state.twitterUser = {};
    },

    [checkForChatToken.pending.type]: (state) => {
      state.chatTokenLoader = true;
    },
    [checkForChatToken.fulfilled.type]: (state) => {
      state.chatTokenLoader = false;
    },
    [checkForChatToken.rejected.type]: (state) => {
      state.chatTokenLoader = false;
    },

    [generateChatToken.pending.type]: (state) => {
      state.chatTokenLoader = true;
    },
    [generateChatToken.fulfilled.type]: (state) => {
      state.chatTokenLoader = false;
    },
    [generateChatToken.rejected.type]: (state) => {
      state.chatTokenLoader = false;
    },

    [removeChatToken.pending.type]: (state) => {
      state.chatTokenLoader = true;
    },
    [removeChatToken.fulfilled.type]: (state) => {
      state.chatTokenLoader = false;
    },
    [removeChatToken.rejected.type]: (state) => {
      state.chatTokenLoader = false;
    },

    [mostTrackedUsers.pending.type]: (state) => {
      state.mostTrackedUserLoader = true;
    },
    [mostTrackedUsers.fulfilled.type]: (state, action) => {
      state.mostTrackedUsers = (action.payload && action.payload.values) || [];
      state.mostTrackedUserLoader = false;
    },
    [mostTrackedUsers.rejected.type]: (state) => {
      state.mostTrackedUserLoader = false;
    },

  },
});

export const { saveUserData, removeUserData, setLoader, removeSearchAccountData } = commonSlice.actions;
export default commonSlice.reducer;
